.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.status-mobileView {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.images {
  background-image: url("./images/logo-nuol.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 238px 359px;
  opacity: 0.1;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.customrow {
  background-color: white;
  margin: 90px 19px 38px 20px;
}
.cardCustom {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.imagePayment {
  width: 100px;
  height: 100px;
}
.borderled {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  height: 98px;
  margin-bottom: 42px;
  padding: 0px 0px 0px 22px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}
.borderled:hover {
  /* background: #057CAE 0% 0% no-repeat padding-box; */
  box-shadow: 0px 3px 6px #00000029;
  /* color: #FFFFFF; */
  border: 1px solid #ececec;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  height: 98px;
  margin-bottom: 42px;
  padding: 0px 0px 0px 22px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}
.borderModalLogin {
  background-color: #057cae;
  color: white;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.borderModalLoginMobile {
  background-color: #057cae;
  color: white;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.cardOneplay {
  width: 577px;
  height: 541px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
}
.borders {
  /* cursor: pointer; */
  border-bottom: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
  padding-top: 80px;
  padding-bottom: 50px;
  padding-left: 250px;
  padding-right: 100px;
  font-size: 18px;
  border-radius: 0px 0px 5px 5px;
}
.img {
  height: 100vh;
  width: 100%;
  /* margin-left: -200px; */
  z-index: 1;
}
.topleft {
  background-color: #2abffe;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.67;
}
.logo {
  width: 150px;
  margin: 15px;
}
.textdev {
  margin-top: 24px;
  text-align: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.card-profile {
  background: #d8d8d8 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  width: 127px;
  height: 147px;
}
.titlebt {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #d8d8d8;
}
.uploadfile {
  border: 1px solid #707070;
}
.buttonBCL {
  width: 206px;
  height: 49px;
  background-color: #057cae;
  /* box-shadow: 0px 3px 6px #00000029; */
  color: #fff;
  border-radius: 5px;
  /* opacity: 1; */
  border: none;
  font-size: 20px;
  font-weight: bold;
}
.buttonBCLDisable {
  width: 206px;
  height: 49px;
  background-color: #d8d8d8;
  box-shadow: 0px 3px 6px #00000029;
  color: #000;
  border-radius: 5px;
  /* opacity: 1; */
  border: none;
  font-size: 20px;
  font-weight: bold;
}

/* .buttonBCL:hover{
  width: 206px;
  height: 49px;
  color: white;
  background: #057CAE 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  border: 1px solid #057CAE;
} */
.btn_custom {
  width: 122px;
  height: 48px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  opacity: 1;
}
.btn_print {
  width: 122px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #137dae;
  border-radius: 6px;
  opacity: 1;
}
.custombutton {
  margin-top: 17px;
  width: 127px;
  height: 31px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
}
.buttonoutline {
  height: 38px;
  border: 2px solid #137dae;
  border-radius: 6px;
  color: #057cae;
  background-color: white;
  /* font-weight: bold; */
}
.button {
  width: 144px;
  height: 48px;
  border: 1px solid #137dae;
  border-radius: 6px;
  color: white;
  background-color: #137dae;
  font-weight: bold;
}
.div-box {
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  width: 255px;
  height: 73px;
  padding: 13px 13px 23px 23px;
}
.d_comment {
  color: #ebebeb;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 261px;
  height: 82px;
  background: #f5f5f559 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}
.textfont-size12 {
  font-size: 12px;
}
.textfont-size14 {
  font-size: 14px;
}
.textfont-size15 {
  font-size: 15px;
}
.textfont-size16 {
  font-size: 16px;
}
.textfont-size18 {
  font-size: 18px;
}
.textfont-size19 {
  font-size: 19px;
}
.textfont-size20 {
  font-size: 20px;
}
.textfont-size22 {
  font-size: 22px;
}
.textfont-size24 {
  font-size: 24px;
}
.textfont-size26 {
  font-size: 26px;
}
.textfont-size28 {
  font-size: 28px;
}
.textfont-size30 {
  font-size: 30px;
}
.textfont-size42 {
  font-size: 42px;
}
.textfont-size40 {
  font-size: 40px;
}
.textfont-size46 {
  font-size: 40px;
}
.textfont-size50 {
  font-size: 50px;
}
.textfont-size72 {
  font-size: 72px;
}
.colorWhite {
  color: white;
}
.colorblack {
  color: #6f6f6f;
}
.text-bold {
  font-weight: bold;
}
.paddings {
  padding: 200px 90px;
}
.mgn {
  margin: 10px 10px 10px 0px;
}
.paddings110 {
  padding: 110px 90px;
}
.cardscol {
  text-align: right;
  background-color: #057cae;
  height: 98px;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  border-radius: 5px 5px 0px 0px;
}
.login1 {
  margin-bottom: 50px;
  padding-left: 30px;
  padding-top: 10px;
  display: flex;
  text-align: left;
  border: 2px solid #057cae;
  border-radius: 10px;
  width: 436px;
  height: 114px;
  background: transparent linear-gradient(270deg, #34c3ff 0%, #1f799f 100%) 0%
    0% no-repeat padding-box;
}
div.login1:hover {
  background: #34c3ff;
}

.loginMobileViewInPlan {
  margin-bottom: 45px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  text-align: left;
  border: 2px solid #057cae;
  border-radius: 10px;
  height: 90px;
  background: transparent linear-gradient(270deg, #34c3ff 0%, #1f799f 100%) 0%
    0% no-repeat padding-box;
}
div.loginMobileViewInPlan:hover {
  background: #34c3ff;
}

.login2 {
  margin-top: 76px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-top: 10px;
  display: flex;
  text-align: left;
  border: 2px solid #ff8800;
  border-radius: 10px;
  width: 436px;
  height: 114px;
  background: transparent linear-gradient(270deg, #ffc27d 0%, #ff8800 100%) 0%
    0% no-repeat padding-box;
}
div.login2:hover {
  background: #ffc27d;
}
a:link,
a:visited {
  text-decoration: none;
}
.overflow-custom {
  overflow-x: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.loginMobileViewOutPlan {
  margin-top: 40px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  text-align: left;
  border: 2px solid #ff8800;
  border-radius: 10px;
  height: 90px;
  background: transparent linear-gradient(270deg, #ffc27d 0%, #ff8800 100%) 0%
    0% no-repeat padding-box;
}
div.loginMobileView:hover {
  background: #ffc27d;
}
a:link,
a:visited {
  text-decoration: none;
}
.overflow-custom {
  overflow-x: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* responsive */
.card-margin-top {
  margin-top: 100px;
}
.card_registerstrationStudents_body {
  padding-left: 5px;
  padding-right: 5px;
}

.bg-register {
  background-color: #d8d8d8;
  padding: 10px;
  /* margin:1px; */
}

.textForm {
  font-size: 25px;
}

.float-right {
  float: right;
}

.marign-button-apply-right {
  margin-left: 18px;
}
.marign-button-apply-left {
  margin-left: 5px;
}
.bg-active {
  background-color: #057cae;
}
@media only screen and (max-width: 768px) and (min-height: 568px) {
  .card_registrationStudents {
    margin: 1px !important;
    padding-bottom: 50px;
  }

  .card-card-dashboard .user-radius {
    width: 70px;
    height: 70px;
    background-color: #fff;
    position: absolute;
    border-radius: 60px;
  }
}

@media only screen and (max-width: 320px) and (min-height: 320px) {
  .logout {
    font-size: 20px;
    margin-right: 20px;
  }
  .card-margin-top {
    margin-top: 20px;
  }
  .card_registrationStudents {
    margin-top: 40px;
    padding-bottom: 50px;
  }
  .navbar-band {
    padding-bottom: 5px;
  }
  .textLogout,
  .status-webView,
  .title-band {
    display: none;
  }
  .status-mobileView {
    display: block;
  }
  .navbar .navbar-band .apply_footer {
    color: #000;
    position: absolute;
    bottom: 0;
    /* padding:20px; */
    /* width: 100%; */
  }

  .button-apply-confirm {
    margin-bottom: 20px;
  }
  .button-confirm {
    margin-bottom: 20px;
  }
  .table {
    /* font-size: 11px; */
    margin-top: 20px;
    /* margin-left:5px;
    margin-right:15px;
    padding-right: 10px; */
  }
  .textForm {
    font-size: 15px;
  }

  .card-card-dashboard .user-radius {
    width: 60px;
    height: 60px;
    background-color: #ffff;
    position: absolute;
    border-radius: 100%;
  }
  .s-text-style {
    text-align: left;
  }
  .borderRight {
    border-left: 1px solid #fff;
  }
  .text-resize5 {
    font-size: 3vw;
  }
}

@media only screen and (max-width: 375px) and (min-height: 385px) {
  .card_registrationStudents {
    margin: 1px !important;
    padding-bottom: 50px;
  }
  .card-margin-top {
    margin-top: 40px;
  }
  .logout {
    font-size: 20px;
    margin-right: 20px;
  }
  .table {
    font-size: 11px;
    margin-top: 20px;
    /* margin-right: 30px; */
  }
  .table th {
    text-align: left;
  }
  .table td {
    text-align: left;
  }
  .navbar-band {
    padding-bottom: 5px;
  }
  .textLogout,
  .status-webView,
  .title-band {
    display: none;
  }
  .status-mobileView {
    display: block;
  }
  .button-apply-confirm {
    margin-bottom: 20px;
  }
  .button-confirm {
    margin-bottom: 20px;
  }

  .card-card-dashboard .user-radius {
    width: 50px;
    height: 50px;
    background-color: #fff;
    position: absolute;
    border-radius: 60px;
  }
  .text-resize6 {
    font-size: 6vw;
  }
  .text-resize5 {
    font-size: 5vw;
  }
  .text-resize4 {
    font-size: 4vw;
  }
  .text-resize3 {
    font-size: 3vw;
  }

  .s-text-style {
    text-align: center;
    margin-top: -400px;
  }

  .borderRight {
    border-left: 1px solid #fff;
  }
}

.card-card-dashboard {
  margin: 0px;
  padding: 18px;
  border: 0px;
  background-color: #057cae;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: center;
  height: 70px;
}
.user-radius {
  width: 110px;
  height: 110px;
  background-color: #fff;
  position: absolute;
  border-radius: 90px;
}
.card-dashboard-body {
  border-bottom: 1px solid #4d4d4d;
  border-right: 1px solid #4d4d4d;
  border-left: 1px solid #4d4d4d;
  border-radius: 0px 0px 5px 5px;
  padding: 28px 35px 35px 35px;
}
.s-text-style {
  text-align: center;
  margin-top: -140px;
}

.detail,
.entranceNumber {
  float: right;
  text-align: center;
  /* margin-right: 40px; */
}
.text-entrance {
  padding-top: 10px;
  text-align: center;
}


@media only screen and (max-width: 768px) and (min-height: 768px) {
  .card-detial-student {
    margin: 1px;
  }
  .borderRight {
    border-right: 1px solid lightslategray;
  }
  
}
.borderRight {
  border-right: 1px solid lightslategray;
}

@media only screen and (max-width: 320px) and (min-height: 320px) {
  .borderRight {
    border-right: 0px;
  }
}

@media only screen and (max-width: 375px) and (min-height: 375px) {
  .borderRight {
    border-right: 0px;
  }
}

@media only screen and (max-width: 414px) and (min-height: 414px) {
  .borderRight {
    border-right: 0px;
  }
  .textLogout,
  .status-webView,
  .title-band {
    display: none;
  }
  .status-mobileView {
    display: block;
  }
}

/* card-detial-student */

@media only screen and (max-width: 768px) and (min-height: 768px) {
  .card-detial-student {
    margin: 1px;
    
  }
  .borderRight {
    border-right: 1px solid lightslategray;
  }
  .text-resize5 {
    font-size: 6vw;
  }
}


@media only screen and (max-width: 768px) and (min-height: 768px) {
  .card-detial-student {
    margin: 1px;
    
  }
  .borderRight {
    border-right: 1px solid lightslategray;
  }
  .text-resize5 {
    font-size: 6vw;
  }
}

.Nav-infoStudent {
  padding: 15px 0px 0px 0px;
}
.text-header {
   font-size: 20px;
   color: #057CAE;
   font-weight: bold;
}
