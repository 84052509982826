
body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

  .form-inline {  
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .form-inline label {
    margin: 5px 10px 5px 0;
  }

  .form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
  }

  .button-section {
    margin: 3%;
  }
  .button {
    padding: 10px 20px;
    background-color: dodgerblue;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
  }

  .button:hover {
    background-color: royalblue;
  }

  .remove {
    background-color: rgb(192, 53, 53);
  }
  .remove:hover {
    background-color: rgb(187, 43, 43);
  }
.row {
  margin-top: 2%;
  margin-bottom: 2%;
}
#search {
  position: absolute;
  width: 150px;
  right: 20px;
  margin-top: -5px;
}
#selectAll {
  width: 18px;
  height: 18px;
}
.h-title {
  display: inline-flex;
  padding: 10px;
  font-weight: bold !important;
}
.list {
  display: flex !important;
}
.heading {
  display: flex;
  padding: 10px;
  background-color: #f1f1f1f1;
}
.chartContent {
  margin-top: -150px;
  font-weight: bold;
  font-size: 18px;
}
.listItem {
  display: inline-flex;
  width: 20%;
  text-align: center;
  padding: 2%;
  margin: 5px;
  text-align: center !important;
  align-items: center;
}
.linne {
  display: flex;
}
section {
  width: 610px;
  margin: 0 auto;
}

#rotate-text {
  writing-mode: vertical-lr;
  transform: rotate(-175deg);
  -webkit-transform: rotate(-175deg);
  -moz-transform: rotate(-175deg);
  -ms-transform: rotate(-175deg);
  -o-transform: rotate(-175deg);
  transform: rotate(-175deg);
  height: 200px;
  padding-left: 0%;
  width: 25px !important;
}
.datakey {
  margin-left: 5%;
  width: 820px;
  margin-top: 0%;
  text-align: right;
  vertical-align: top;
  border: none !important;
}
.nav-tabs .nav-link.active {
  color: #495057!important;
  background-color: #fff;
  border-color: #fff #fff #fff;
}

.nav-link{
  color:  #495057!important;
}

/* Form.Control, select,.form-control, input[type="text"]{
  border:none!important;
} */
.registration{
  float: right;
  align-items: right;
  margin-right: 160px;
  margin-top: -4px;
  background-color: #057CAE;
}
.vertical-10{
  height: 100px;
}
.browserImage{
  display: flex;
  margin: 5px;
  width: 140px;
  height: 160px;
  border-radius: 5px;
  align-items: flex-end;
  border: 1px #ccc solid;
margin-left: 20px;
}
.menu-setting:hover
{
  background-color: #f1f1f2;
}
.left{
  text-align: left!important;
}
