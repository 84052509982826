body {
  margin: 0;
  font-family: "Noto Sans Lao UI", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "PhetsarathOT";
  src: url(./font/PhetsarathOT.ttf);
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

* {
  font-family: "PhetsarathOT";
}
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #eee;
}
/* .container {
  width: 40vw;
  height: auto;
  margin: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 8px solid #c6332a;
  top: 0px;
  position: relative;
  box-shadow: 0 3px 5px #ccc;
  text-align: center;
  padding-bottom: 20px;
}
h2,
h3 {
  padding: 0;
  margin: 0;
  color: #333;
}
.title {
  padding: 20px 0;
  color: #333;
}
.border {
  border-bottom: 2px solid #c6332a;
  width: 40%;
  margin: auto;
}
.btn-payment {
  border: none;
  outline: none;
  padding: 14px 40px;
  color: #fff;
  background-color: #c6332a;
  border-radius: 3px;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0 3px 3px #ccc;
  margin-top: 20px;
}
.btn-payment:hover {
  background-color: #b12d27;
}
.details {
  padding: 20px;
}
p {
  padding: 1px 0;
  line-height: 0.4rem;
}
.amount {
  padding-top: 30px;
} */

